<template>
  <PageDefault>
    <template v-slot:content>
      <div class="mt-7 has-text-centered">
        <div class="is-size-1 has-text-weight-semibold" translate>
          ¡Ups! La venta de este evento no está disponible en este momento.
        </div>
        <p translate>
          Localiza todos los eventos disponibles en nuestra web
          <a :href="theme.href">{{ theme.name }}</a>
          .
        </p>
      </div>
    </template>
  </PageDefault>
</template>

<script>
export default {
  name: "Status404",
  computed: {
    theme: function () {
      return this.$theme()
    },
  },
}
</script>
